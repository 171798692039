// extracted by mini-css-extract-plugin
export var demoPage = "style-module--demoPage--BwPEr";
export var demoPageHero = "style-module--demoPageHero--Ssgw1";
export var embedResponsive = "style-module--embedResponsive--YwXZJ";
export var hawksomeCard = "style-module--hawksomeCard--q7xj+";
export var hawkwardCard = "style-module--hawkwardCard--RAjK8";
export var link = "style-module--link--r6TXi";
export var signupLink = "style-module--signupLink--rEGXn";
export var takeFlightGrid = "style-module--takeFlightGrid--7eZvP";
export var takeFlightGridItem = "style-module--takeFlightGridItem--mYrmU";
export var talkWithExpert = "style-module--talkWithExpert--jx215";