// extracted by mini-css-extract-plugin
export var alteredGrid = "style-module--alteredGrid--8P-FC";
export var alteredGridRight = "style-module--alteredGridRight--2Y-bl";
export var apiTesting = "style-module--apiTesting--YtxEW";
export var bottomIcons = "style-module--bottomIcons--5Wdjd";
export var buttonContainer = "style-module--buttonContainer--tVujF";
export var cicdWorkflow = "style-module--cicdWorkflow--7iaBF";
export var closedQuote = "style-module--closedQuote--suv4I";
export var ctaSub = "style-module--ctaSub--HLEd2";
export var customizable = "style-module--customizable--0stjQ";
export var demoImg = "style-module--demoImg--Ufryl";
export var demoQuote = "style-module--demoQuote--fLtCp";
export var demoh3 = "style-module--demoh3--4XA6F";
export var difference = "style-module--difference--9cjeb";
export var enableDevs = "style-module--enableDevs--uoZcj";
export var grid = "style-module--grid--reY0C";
export var gridOneThird = "style-module--gridOneThird--oo0OZ";
export var gridTwoThirds = "style-module--gridTwoThirds--miKnq";
export var halfLeft = "style-module--halfLeft--Ou5Y7";
export var halfRight = "style-module--halfRight--BArwa";
export var hawksomeCard = "style-module--hawksomeCard--0fjqN";
export var homepageCommon = "style-module--homepageCommon--zUN0W";
export var integratesWorkflow = "style-module--integratesWorkflow--Bw22O";
export var interoperability = "style-module--interoperability--txlOM";
export var leftSide = "style-module--leftSide--tcOcm";
export var leftSide5050 = "style-module--leftSide5050--tU--z";
export var mobileHideImg = "style-module--mobileHideImg--r59fK";
export var mobileHideImgDemo = "style-module--mobileHideImgDemo--ISgIT";
export var noMarginBottom = "style-module--noMarginBottom--k6oX0";
export var noPMargin = "style-module--noPMargin--s1kZN";
export var oneThird = "style-module--oneThird--1LVD8";
export var oneThirdReverse = "style-module--oneThirdReverse--m1vO6";
export var openQuote = "style-module--openQuote--TKwFm";
export var quoteText = "style-module--quoteText--sIIvR";
export var quoteWrapper = "style-module--quoteWrapper--Ocavj";
export var rightSide = "style-module--rightSide--ZgCLM";
export var saferCode = "style-module--saferCode--97arf";
export var smallerImg = "style-module--smallerImg--M+PyX";
export var solutionsTwoRows = "style-module--solutionsTwoRows--i36GN";
export var staticImageContainer = "style-module--staticImageContainer--+IkwI";
export var staticImageItem = "style-module--staticImageItem--Av+v3";
export var testAPIs = "style-module--testAPIs--XRPx5";
export var topIcon = "style-module--topIcon--kOeaC";
export var topIconBigger = "style-module--topIconBigger--PHS7f";
export var twoRows = "style-module--twoRows--uTra+";
export var twoRowsReverse = "style-module--twoRowsReverse--zNccb";
export var twoThirds = "style-module--twoThirds--wUTKp";
export var wide = "style-module--wide--5Sp68";
export var wideTextContainer = "style-module--wideTextContainer--Rrt0S";
export var wrapper = "style-module--wrapper--gXL4F";