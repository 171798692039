// extracted by mini-css-extract-plugin
export var btnAsh = "style-module--btnAsh--kDOt4";
export var btnOutline = "style-module--btnOutline--0mpt0";
export var btnPrimary = "style-module--btnPrimary--mm-L4";
export var btnSecondary = "style-module--btnSecondary--adFPH";
export var comparison = "style-module--comparison--6TqUW";
export var customCard = "style-module--customCard--60-c6";
export var enterpriseCard = "style-module--enterpriseCard--JR8hx";
export var freeCard = "style-module--freeCard--71Kls";
export var getStarted = "style-module--getStarted--UDEhn";
export var mainPricingCards = "style-module--mainPricingCards--GlEzA";
export var pageHeadingContainer = "style-module--pageHeadingContainer--OSHIQ";
export var planDescriptionCardHeader = "style-module--planDescriptionCardHeader--wbrb7";
export var planDescriptionCards = "style-module--planDescriptionCards--AQXXS";
export var planDescriptionContainer = "style-module--planDescriptionContainer--AFGmP";
export var pricingAbridgedContainer = "style-module--pricingAbridgedContainer--Tep5I";
export var pricingAbridgedSwitcher = "style-module--pricingAbridgedSwitcher--prFp6";
export var pricingCardContainer = "style-module--pricingCardContainer--AyI66";
export var pricingCardContent = "style-module--pricingCardContent--fsl4f";
export var pricingPage = "style-module--pricingPage--BE-14";
export var pricingSection = "style-module--pricingSection--cGCT9";
export var princingPholosopyContainer = "style-module--princingPholosopyContainer--HMlf6";
export var proCard = "style-module--proCard---pZ2g";