import React, { CSSProperties } from 'react';
import * as containerStyles from './style.module.scss';
import * as buttonStyles from '../Pricing/style.module.scss';
import parse from 'html-react-parser';
import '../Homepage/style.scss';
import OpenQuote from '../../../images/homepage/open-quote.svg';
import ClosedQuote from '../../../images/homepage/closed-quote.svg';
import ArrowRightIcon from '../../../images/homepage/arrow-right.svg';
import Button from '../Button';

type Variant = 'wide' | 'wide5050' | 'twoThirds' | 'oneThird' | 'quotation' | 'oneThirdReverse';

interface ContainerProps {
  variant?: Variant;
  heading?: string;
  description: string;
  button1Text?: string;
  button1Link?: string;
  button2Text?: string;
  button2Link?: string;
  image?: string;
  smallerImg?: string;
  icon?: string;
  quoteAuthor?: string;
  cssClass?: string;
  imgStyles?: CSSProperties;
  bottomIcons?: string[];
}

export const Container: React.FunctionComponent<ContainerProps> = ({
  variant,
  heading,
  description,
  button1Text,
  button1Link,
  button2Text,
  button2Link,
  image,
  smallerImg,
  icon,
  quoteAuthor,
  cssClass,
  imgStyles,
  bottomIcons,
}) => {
  const isWideVariant = variant === 'wide';
  if (isWideVariant || variant === 'wide5050') {
    return (
      <div className={`${containerStyles.wrapper}`}>
        <section
          className={`${containerStyles.homepageCommon} ${containerStyles.wide} ${
            cssClass && containerStyles[cssClass]
          } ${cssClass}`}
        >
          <div className={`${isWideVariant ? containerStyles.leftSide : containerStyles.leftSide5050}`}>
            <div className={`${containerStyles.wideTextContainer} ${variant === 'wide5050' && 'pt-0'}`}>
              {icon && <img className={containerStyles.topIcon} src={icon} alt={`${heading} icon`} />}
              <h3 className={`${button1Text === 'Request a Demo' && containerStyles.demoh3}`}>
                {heading && parse(heading)}
              </h3>
              {(cssClass === 'mobileHideImg' || cssClass === 'mobileHideImgDemo') && imgStyles && (
                <img style={imgStyles} src={image} alt={description} />
              )}
              <p>{description}</p>
              {button1Link && (
                <a href={button1Link}>
                  <button
                    className={
                      isWideVariant || cssClass === 'difference' || cssClass === 'mobileHideImgDemo'
                        ? 'getStartedButton hoverWiggle'
                        : buttonStyles.btnPrimary
                    }
                  >
                    {button1Text}{' '}
                    {(isWideVariant ||
                      cssClass === 'difference' ||
                      cssClass === 'integratesWorkflow' ||
                      cssClass === 'mobileHideImgDemo') && (
                      <em>
                        <img src={ArrowRightIcon} alt="Right Arrow Icon" />
                      </em>
                    )}
                  </button>
                </a>
              )}
            </div>
          </div>
          <div className={containerStyles.rightSide}>
            <img
              style={imgStyles}
              src={image}
              alt={description}
              className={`${button1Text === 'Request a Demo' && containerStyles.demoImg}`}
            />
            {smallerImg && (
              <img style={imgStyles} src={smallerImg} alt={description} className={containerStyles.smallerImg} />
            )}
          </div>
        </section>
      </div>
    );
  } else if (variant === 'twoThirds' || variant === 'oneThird' || variant === 'oneThirdReverse') {
    return (
      <div
        className={`${containerStyles.wrapper} ${containerStyles.grid} ${
          variant === 'twoThirds' ? containerStyles.gridTwoThirds : containerStyles.gridOneThird
        } ${variant === 'oneThirdReverse' && containerStyles.oneThirdReverse} ${cssClass && containerStyles[cssClass]}`}
      >
        <section
          className={`${variant === 'twoThirds' ? containerStyles.twoThirds : containerStyles.oneThird} ${
            containerStyles.homepageCommon
          }`}
        >
          <div className={containerStyles.leftSide}>
            {icon && (
              <img
                className={`${cssClass === 'cicdWorkflow' ? containerStyles.topIconBigger : containerStyles.topIcon}`}
                src={icon}
                alt={`${heading} icon`}
              />
            )}
            <h3>{heading && parse(heading)}</h3>
            <p className={cssClass === 'noPMargin' ? containerStyles.noMarginBottom : ''}>{description}</p>
            {button1Link && (
              <a href={button1Link}>
                <button className={`getStartedButton hoverWiggle ${variant === 'oneThird' && 'oneThirdButton'}`}>
                  {button1Text}{' '}
                  <em>
                    <img src={ArrowRightIcon} alt="Right Arrow Icon" />
                  </em>
                </button>
              </a>
            )}
            {bottomIcons && (
              <div className={`${containerStyles.bottomIcons}`}>
                {bottomIcons?.map((icon, idx) => (
                  <img key={idx} src={icon} alt={description} />
                ))}
              </div>
            )}
          </div>
          {image && (
            <div className={containerStyles.rightSide}>
              <img src={image} alt={description} />
            </div>
          )}
        </section>
      </div>
    );
  } else if (variant === 'quotation') {
    return (
      <div className={`${containerStyles.wrapper}`}>
        <div className={`${containerStyles.quoteWrapper} ${!!cssClass && containerStyles[cssClass]}`}>
          <img className={containerStyles.openQuote} src={OpenQuote} alt="Open Quote" />
          <div className={containerStyles.quoteText}>
            <p>{description}</p>
            <h3>{quoteAuthor}</h3>
          </div>
          <img className={containerStyles.closedQuote} src={ClosedQuote} alt="Closed Quote" />
        </div>
      </div>
    );
  }
  return (
    <div className={`${containerStyles.wrapper}`}>
      <div className={`${cssClass && containerStyles[cssClass]}`}>
        <h2>{heading && parse(heading)}</h2>
        <p>{description}</p>
        <div className={containerStyles.buttonContainer}>
          <a href={button1Link} target="_blank" rel="noreferrer">
            <Button
              type="button"
              variant="button-simple"
              label={button1Text as string}
              style="btn-primary"
              disabled={false}
              size="header"
              alignment="center"
            />
          </a>
          {button2Link && (
            <a href={button2Link} target="_blank" rel="noreferrer">
              <button className={buttonStyles.btnOutline}>{button2Text}</button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Container;
