import React, { useState } from 'react';
import * as demoStyles from './style.module.scss';
import Button from '../Button';
import RocketIcon from '../../../images/demo/rocket.svg';
import PaperIcon from '../../../images/demo/paper.svg';
import Container from '../Container';
import images from '../../../images/homepage/index';
import { defaultButtonText } from 'components/RequestADemoModal/constants';
import { DOCS_URL, PRICING_URL, SIGNUP_URL, REQUEST_DEMO_ANCHOR } from 'core/links';
import { DefaultModal } from '../../../foundations/Modals';
import { Helmet } from 'react-helmet';

export const DemoPage: React.FunctionComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [scriptHasLoaded, setScriptHasLoaded] = useState(false);

  const handleModalClick = () => {
    setShowModal(true);
    setScriptHasLoaded(true);
  };

  return (
    <div>
      <Helmet defer="false">
        <script type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
        {scriptHasLoaded && (
          <script>
            {`window?.hbspt?.forms?.create({
                  region: 'na1',
                  portalId: '7478034',
                  formId: 'ceb4c8a9-f3f2-4d5a-9986-7bec3492ceaf',
                  target: '#hubspotForm',
                })`}
          </script>
        )}
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "VideoObject",
          "name": "API Security Testing for Teams that Deploy Software Every Day",
          "description": "StackHawk is the only modern DAST and API security testing tool that runs in CI/CD, enabling developers to quickly find, triage, and fix security issues before they hit production.
    ",
          "thumbnailUrl": [
            "https://www.stackhawk.com${images.demoThumb}"
           ],
          "uploadDate": "2016-03-31T08:00:00+08:00",
          "duration": "PT1M54S",
          "contentUrl": "https://fast.wistia.net/embed/iframe/lkuvf9qoqa",
          "embedUrl": "https://www.stackhawk.com/",
          "interactionStatistic": {
            "@type": "InteractionCounter",
            "interactionType": { "@type": "WatchAction" },
            "userInteractionCount": 5647018
          },
          "regionsAllowed": "US,NL"
        } 
    
    `}</script>
      </Helmet>
      <div className={demoStyles.demoPage}>
        <div className={demoStyles.demoPageHero}>
          <h1>
            Test Your APIs Before <br /> They Leave the Nest
          </h1>
          <p>
            We built StackHawk to give developers the resources and tools to find, understand, and most importantly, fix
            security vulnerabilities before they ship code. Watch our demo to see our platform in flight.
          </p>
          <div className={`mt-3 pb-4 mb-3 ${demoStyles.talkWithExpert}`}>
            <div>
              <span>Curious about improving your API testing with StackHawk? </span>
              <br />
              <button type="button" className="mt-3 btn btn-primary" onClick={handleModalClick}>
                Talk to an Expert!
              </button>
            </div>
          </div>
          <DefaultModal
            className="contact-modal"
            size="lg"
            onHide={() => setScriptHasLoaded(false)}
            showButtons={false}
            show={showModal}
            showModal={setShowModal}
            content={<div className="p-5" id="hubspotForm"></div>}
          />
        </div>

        <div>
          <div className={demoStyles.embedResponsive}>
            <iframe
              src="https://fast.wistia.net/embed/iframe/lkuvf9qoqa?seo=true&videoFoam=true"
              title="StackHawk Demo Video"
              allow="fullscreen"
              allowtransparency="true"
              frameBorder="0"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              msallowfullscreen="true"
              width="100%"
              height="100%"
            />
          </div>
          <div className={`mt-5 mb-4`}>
            <a
              href="https://auth.stackhawk.com/signup"
              className={`text-primary font-semibold text-xl get-started-button hoverWiggle ${demoStyles.signupLink}`}
            >
              <div>Get Started for Free</div>
              <img src={images.ArrowRightIcon} alt="Right Arrow Icon" />
            </a>
          </div>
        </div>

        <div>
          <h2>Ready to Take Flight with StackHawk?</h2>
          <div className={demoStyles.takeFlightGrid}>
            <div className={demoStyles.takeFlightGridItem}>
              <img src={PaperIcon} alt="Paper Icon" />
              <h3>Check out our Plans</h3>
              <p>We support all size companies, so you can choose the plan that is right for you. </p>
              <Button
                type="button"
                variant="button-simple"
                label="Explore Plans"
                url={PRICING_URL}
                style="outline"
                disabled={false}
                size="btn"
                containerWidth="fit-content"
              />
            </div>
            <div className={demoStyles.takeFlightGridItem}>
              <img src={RocketIcon} alt="Rocket Icon" />
              <h3>Start Scanning Now</h3>
              <p>Learn how to start scanning your application or API in just minutes with your free trial.</p>
              <Button
                type="button"
                variant="button-simple"
                url={SIGNUP_URL}
                label="Start Free Trial"
                style="outline"
                disabled={false}
                size="btn"
                containerWidth="fit-content"
              />
            </div>
          </div>
        </div>
        <div className={demoStyles.hawkwardCard}>
          <Container
            image={images.ApiCoverageImg}
            button1Text={defaultButtonText}
            button1Link={REQUEST_DEMO_ANCHOR}
            variant="wide5050"
            heading="Let’s not make<br/> this Hawkward!"
            description="Have more questions and would like a live demo? Reach out and one of our Hawk’s will be happy to guide you through our platform."
            cssClass="mobileHideImgDemo"
          />
        </div>
        <div>
          <Container
            description="There’s a lot at stake when protecting our customer’s data. Our brand is on the line."
            variant="quotation"
            quoteAuthor="— Director of Cybersecurity, PGA Tour"
            cssClass="demoQuote"
          />
        </div>
        <div className={demoStyles.hawksomeCard}>
          <Container
            heading="Get Hands-On Experience <br/> <span>Give Us A Test Drive!</span>"
            description="We know you might want to test drive a full version of security software before you talk to us. So, Get It On!"
            button1Text="Start Your Free Trial"
            button1Link={SIGNUP_URL}
            button2Text="Read the Docs"
            button2Link={DOCS_URL}
            cssClass="hawksomeCard"
          />
        </div>
      </div>
    </div>
  );
};
